module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Airstage Cloud","short_name":"ASC","start_url":"/","background_color":"#1ba088","display":"minimal-ui","icon":"src/images/favicons/favicon-16x16.png","icons":[{"src":"src/images/favicons/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"src/images/favicons/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"src/images/favicons/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"src/images/favicons/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/images/favicons/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/images/favicons/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"src/images/favicons/android-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicons/apple-icon.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicons/apple-icon-57x57.png","sizes":"57x57","type":"image/png"},{"src":"src/images/favicons/apple-icon-60x60.png","sizes":"60x60","type":"image/png"},{"src":"src/images/favicons/apple-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"src/images/favicons/apple-icon-76x76.png","sizes":"76x76","type":"image/png"},{"src":"src/images/favicons/apple-icon-114x114.png","sizes":"144x144","type":"image/png"},{"src":"src/images/favicons/apple-icon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"src/images/favicons/apple-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"src/images/favicons/apple-icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"src/images/favicons/apple-icon-180x180.png","sizes":"180x180","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c8ad0bd9d8c7e50278fb79f8ded6fd29"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
